<template>
  <div>
    <h1 class="text-2xl font-semibold text-gray-900">
      Feedback ritten van chauffeurs
    </h1>

    <div class="grid grid-cols-4 gap-4 max-w-4xl">
      <UISelectChauffeur v-model="filters.user_id" nullable class="w-full" label="Chauffeur" />
    </div>

    <div class="py-4">
      <UITableResponsive :items="items" :headers="['Datum', 'Chauffeur', 'Rit_gegevens', 'Feedback']" :suffix-headers="['Acties']">
        <template v-slot:colgroup>
          <colgroup>
            <col class="w-0" />
            <col class="w-0" />
            <col class="w-0" />
            <col class="w-auto" />
            <col class="w-0" />
          </colgroup>
        </template>
        <template v-slot:item-Datum="{ item }">
          {{ dateTimeStringLocale(item.creation_date)}}
        </template>
        <template v-slot:item-Chauffeur="{ item }">
          {{ store.getters.chauffeur(item.user_id)?.label }}
        </template>
        <template v-slot:item-Rit_gegevens="{ item }">
          <TextRitDeel class="font-bold" short :is-retour="true" :deel-retour="item.is_retour" />
          <div class="border-dashboard-dark border pr-2 pt-1 rounded flex flex-col">
            <GoogleLink
              v-for="(adres, index) in (item.is_retour ? item.adressen_retour : item.adressen_heen)"
              :key="adres.adres + index + item.id"
              :icon="adres.is_luchthaven ? 'fas fa-plane' : 'fas fa-map-marker-alt'"
              :adres="adres.adres"
              class="block ml-1"
            />
          </div>
        </template>
        <template v-slot:item-Feedback="{ item }">
          <p class="block pr-8 break-words whitespace-normal">
            {{ item.feedback }}
          </p>
        </template>
        <template v-slot:item-Acties="{ item }">
          <ButtonRitInfo :id="item.rit_id" />
          <button @click="handleBehandeld(item)" :disabled="item.is_behandeld" class="ml-1 btn success">
            {{ item.is_behandeld ? 'Behandeld!' : 'Markeer als behandeld' }}
          </button>
        </template>
      </UITableResponsive>
      <UITablePagination class="mt-2" :count="count" v-model:limit="limit" v-model:offset="offset" />
    </div>
  </div>
</template>



<script setup>
import { defineAsyncComponent, ref } from 'vue'
import { useStore } from 'vuex'

import UITableResponsive from '@/components/UI/Table/Responsive'
import GoogleLink from '@/components/Google/Link.vue'
import ButtonRitInfo from '@/components/UI/Button/RitInfo.vue'

import UISelectChauffeur from '@/components/UI/Select/Chauffeur.vue'

import { dateTimeStringLocale } from '@/functions/formatDate'
import usePaginatedApi from '@/hooks/usePaginatedApi'
import useApi from '@/hooks/useApi'

const TextRitDeel = defineAsyncComponent(() => import('@/components/UI/Text/RitDeel.vue'))
const UITablePagination = defineAsyncComponent(() => import('@/components/UI/Table/Pagination.vue'))

const store = useStore()
const api = useApi()
const filters = ref({
  user_id: null,
})

const { limit, offset, count, items, getData } = usePaginatedApi('/api/dashboard/feedback/list', filters, { watch: true })

const handleBehandeld = (item) => {
  api('RIT_FEEDBACK_BEHANDELD', { id: item.id }).then(() => getData())
}
</script>
